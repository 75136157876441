import React, {Fragment, useState} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/post.scss"
import moment from "moment";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player";

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function Post({ data, location }) {
  const post = data?.wpPost
  const posts = data?.allWpPost?.edges
  const recentPosts = [...posts].slice(0, 6)
  let search = []
  const [searchedPosts, setSearchedPosts] = useState(search)

  function searching(event) {
      let searchingPosts = posts.filter(currentPost => {
          return currentPost?.node?.title?.toLowerCase().includes(event.target.value.toLowerCase())
      })
      searchingPosts = event.target.value.length === 0 ? [] : searchingPosts
      return setSearchedPosts([...searchingPosts])
  }

  const image = getImage(post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData)

  return (
      <Fragment>
        <Seo post={post} />
        <Layout location={location}>
            <div className="single-post-wrapper">
                <div className="main">
                    {!post?.blog?.featuredVideo?.mediaItemUrl &&
                      <GatsbyImage image={image} alt={post?.featuredImage?.altText} />
                    }
                    {post?.blog?.featuredVideo?.mediaItemUrl &&
                        <div className="player-wrapper">
                            <ReactPlayer
                                url={post?.blog?.featuredVideo?.mediaItemUrl}
                                width="100%"
                                height="100%"
                                light={post?.featuredImage?.node?.localFile?.publicURL}
                                playing="false"
                                className="react-player"
                            />
                        </div>
                    }
                    <div className="text-content-wrapper">
                        <h1 className="title book">{post?.title}</h1>
                        <div className="subtitle blue">
                            Posted By {post?.author?.node?.name} | {moment(post?.date).format("MMMM DD, Y")}
                        </div>
                        <div className="description" dangerouslySetInnerHTML={{__html: post?.content}}></div>
                    </div>
                </div>
                <div className="sidebar">
                    <div className="searchbar">
                        <input type="text" onChange={(event) => {searching(event)}} />
                        <button className="button">Search</button>
                    </div>
                    <div className="search-results">
                        {searchedPosts && searchedPosts?.map((searchedPostItem) => {
                            let searchedPost = searchedPostItem?.node
                            if (searchedPost.id !== post.id) {
                                return (
                                    <a className="article-title" href={searchedPost?.uri}>{searchedPost?.title}</a>
                                )
                            }
                            return null
                        })}
                    </div>
                    <div className="articles-list-wrapper">
                        <h3 className="subtitle">Recent Articles</h3>
                        <div className="articles-list">
                            {recentPosts && recentPosts?.map((recentPostItem) => {
                                let recentPost = recentPostItem?.node
                                if (recentPost.id !== post.id) {
                                    return (
                                        <a className="article-title" href={recentPost?.uri}>{recentPost?.title}</a>
                                    )
                                }
                                return null
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
      </Fragment>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
       title
       metaDesc
       focuskw
       metaKeywords
       opengraphTitle
       opengraphDescription
       opengraphImage {
         altText
         sourceUrl
         srcSet
       }
       twitterTitle
       twitterDescription
       twitterImage {
         altText
         sourceUrl
         srcSet
       }
       canonical
       cornerstone
       schema {
         articleType
         pageType
         raw
       }
      }
      id
      uri
      title
      content
      date
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      blog {
        fieldGroupName
        previewcontent
        subtitle
        title
        featuredVideo {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

    }
    allWpPost {
      edges {
        node {
          id
          uri
          title
          date
        }
      }
    }
  }
`
